@import '@/styles/mixins.scss';

.wrapper {
    position: relative;

    transform: translateY(10px);
    opacity: 0.01;
    z-index: 1;

    border-radius: 16px;

    height: 244px;

    &.hovered {
        box-shadow: var(--shadow-card-light-hover);

        z-index: 20;
    }

    &.enter {
        opacity: 1;
        transform: translateY(0);
        transition: 150ms ease-out;
    }
}

.box {
    @include flex-column;
    width: 100%;
    min-height: 244px;
    padding: 32px;
    border-radius: 16px;
    gap: 16px;
    border: 1px solid var(--colors-gray-light-80);
    flex-shrink: 0;
    transition: 200ms ease-out;
    background: white;
}

.hidden-box {
    position: absolute;
    top: 0px;
    visibility: hidden;

    &.visible {
        visibility: visible;
        z-index: 2;
    }
}

.reviewer {
    @include flex-row;
    align-items: center;
    position: relative;
    gap: 16px;
}

.reviewer-details {
    @include flex-column;
    flex-grow: 1;
}

.name {
    @include caption-L;
    color: var(--colors-gray-dark-70);
}

.date {
    @include text-S;
    color: var(--colors-gray-dark-10);
}

.comment {
    @include text-S;
    color: var(--colors-gray-dark-90);
    display: -webkit-box;

    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    &.full {
        display: block;
        height: fit-content;
    }
}

.google {
    flex-shrink: 0;
}

@media screen and (max-width: 764px) {
    .wrapper {
        height: fit-content;
    }

    .box {
        min-height: auto;
        padding: 24px;
    }
}

@media screen and (max-width: 524px) {
    .box {
        padding: 24px;
    }
}
